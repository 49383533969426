<template>
  <NavBar/>
  <div class="text-gray-800 text-[32px] md:text-[48px] text-5xl font-bold leading-[48px] mt-[70px] md:mt-[100px] mb-[72px] montsr text-center lg2:text-left">Контакты</div>

  <div class="grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-10">

    <div class="bg-white rounded-[15px] border-2 border-blue-700 lg2:w-[360px] py-4 h-[200px] px-[20px] space-y-1">
      <h1 class=" text-[19px] sm2:text-[23px] lg2:text-[32px] mx-auto font-extrabold tracking-wide text-blue-700 montsr text-center">Ресепшен</h1>
      <div class="flex items-center flex-row">
      <img class="w-[13px] ml-[3px] mr-[8px] h-[18px]" src="../../assets/icons/contactsIco/geo.png">
      <p class="text-neutral-700 text-lg font-normal tracking-tight ml-1">г. Тюмень, ул. Луначарского, 2 к. 5</p>
    </div>
      <div class="flex flex-row">
        <img class="mr-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
        <p class="text-neutral-700 text-lg font-normal tracking-tight">+7 (3452) 28-37-78</p>
      </div>
      <div class="flex flex-row">
        <img class="mr-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
        <p class="text-neutral-700 text-lg font-normal tracking-tight">+7 (3452) 28-37-22</p>
      </div>
      <div class="flex items-center flex-row">
        <img class="mr-[8px] h-[16px] w-[21px]" src="../../assets/icons/contactsIco/mail.png" alt="">
        <p class="text-neutral-700 text-lg font-normal tracking-tight">sotsz@tyuiu.ru</p>
      </div>
    </div>


    <div class="bg-white rounded-[15px] border-2 border-blue-700 lg2:w-[360px] py-4 h-[200px] px-[20px] space-y-1">
      <h1 class=" text-[19px] sm2:text-[23px] lg2:text-[32px] mx-auto font-extrabold tracking-wide text-blue-700 montsr text-center">Социальные сети</h1>

      <a href="https://vk.com/zodchiy72/" class="flex flex-row gap-3 space-y-0 pb-[8px] mt-[26px] w-full ml-2 items-center">
          <img class="h-[19px] w-[28px]" src="../../assets/icons/contactsIco/vk.png" alt="">
          <p class="montsr">Зодчий Вконтакте</p>
        </a>
        <a href="https://invite.viber.com/?g2=AQBIbVg8JoaOTUkYhb%2F6BbYgXpsd5Ob5M3BlbwHEsBdWJg2X9czyPN1eKelTMeOo&lang=ru" class="flex ml-2 flex-row gap-3 space-y-0 w-full items-center">
          <img class="ml-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
          <p class="montsr">Зодчий Viber</p>
        </a>
    </div>


  </div>



  <div class="text-gray-800 text-[32px] md:text-[48px] text-5xl font-bold leading-[48px] mt-[100px] mb-[32px] montsr text-center lg2:text-left">Администрация</div>
  <div class="justify-center">
    <div class="grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-10">

      <div class="bg-white rounded-[15px] rounded-blue lg2:w-[360px] py-4 h-[200px] px-[23px]">
        <h1 class="text-blue-700 text-lg font-bold tracking-tight">Авакова Анна Олеговна</h1>
        <p class="my-[23px] text-neutral-700 text-lg font-normal tracking-tight">Старший администратор центра</p>
        <div class="flex flex-row">
          <img class="mr-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">+7 (3452) 28-37-56</p>
        </div>
        <div class="flex items-center flex-row">
          <img class="mr-[8px] h-[16px] w-[21px]" src="../../assets/icons/contactsIco/mail.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">avakovaao@tyuiu.ru</p>
        </div>
      </div>

      <div class="bg-white rounded-[15px] rounded-blue lg2:w-[360px] py-4 h-[200px] px-[23px]">
        <h1 class="text-blue-700 text-lg font-bold tracking-tight">Мохирева Мария Викторовна</h1>
        <p class="my-[23px] text-neutral-700 text-lg font-normal tracking-tight">Специалист по спорту</p>
        <div class="flex flex-row">
          <img class="mr-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">+7 (3452) 28-37-55</p>
        </div>
        <div class="flex items-center flex-row">
          <img class="mr-[8px] h-[16px] w-[21px]" src="../../assets/icons/contactsIco/mail.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">mohirevamv@tyuiu.ru</p>
        </div>
      </div>

      <div class="bg-white rounded-[15px] rounded-blue lg2:w-[360px] py-4 h-[200px] px-[23px]">
        <h1 class="text-blue-700 text-lg font-bold tracking-tight">Набокова Татьяна Борисовна</h1>
        <p class="my-[23px] text-neutral-700 text-lg font-normal tracking-tight">Договорной отдел для юр. лиц</p>
        <div class="flex flex-row">
          <img class="mr-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">+7 (3452) 28-37-58</p>
        </div>
        <div class="flex items-center flex-row">
          <img class="mr-[8px] h-[16px] w-[21px]" src="../../assets/icons/contactsIco/mail.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">nabokovatb@tyuiu.ru</p>
        </div>
      </div>

      <div class="bg-white rounded-[15px] rounded-blue lg2:w-[360px] py-4 h-[200px] px-[23px]">
        <h1 class="text-blue-700 text-lg font-bold tracking-tight">Новикова Анастасия Михайловна</h1>
        <p class="my-[23px] text-neutral-700 text-lg font-normal tracking-tight">Договорной отдел для юр. лиц</p>
        <div class="flex flex-row">
          <img class="mr-[8px] h-[19px] w-[19px]" src="../../assets/icons/contactsIco/mobile.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">+7 (3452) 28-39-89</p>
        </div>
        <div class="flex items-center flex-row">
          <img class="mr-[8px] h-[16px] w-[21px]" src="../../assets/icons/contactsIco/mail.png" alt="">
          <p class="text-neutral-700 text-lg font-normal tracking-tight">novikovaam@tyuiu.ru</p>
        </div>
      </div>

  </div>
</div>

<MapYa/>



<div class="flex h-[111px] md:h-[111px]"/><Footer class=""/>


</template>

<script>
  import NavBar from '../sharing/NavBar'
  import Footer from '../sharing/FooterBar'
  import MapYa from '../sharing/MapYa.vue'


  export default {
    components: {
      NavBar,
      MapYa,
      Footer,
    }
  }
</script>

